export class PageModule {
    constructor () {
      this.currentPage = 1
      this.pageSize = 10
      this.total = 0
    }
    setTotal (val) {
      this.total = val
    }
    init () {
        this.currentPage = 1
        this.pageSize = 10
        this.total = 0
    }
    setPageSize (val) {
        this.pageSize = val
    }
    setCurrentPage (val) {
        this.currentPage = val
    }
    getParamsJson () {
        return {
            page: this.currentPage,
            pageSize: this.pageSize
        }
    }
}