<template>
  <div class="box-page">
    <a-row :gutter="12">
      <a-col :span="12">
        <div class="card-data" style="padding-left: 5px">
          <div style="padding: 15px 25px;background: #fff;border-radios: 4px;display: flex;
      box-shadow: 1px 1px 5px #999;">
            <div class="jin-a" style="width: 24%">
              <div class="num" style="font-size: 28px;font-weight: bold;color: #333333;line-height: 41px">
                <span>{{ todayMap.checkCharge }}</span>
              </div>
              <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
                <span>今日消费</span>
              </div>
            </div>
            <div class="zin-a" style="width: 16%;border-right: 1px solid #e4e4e4">
              <div class="sun" style="font-size: 18px;color: color: #787878;line-height: 41px;height: 41px;">
                <span style="color: #FF0000;font-size: 12px;">{{ yesterdayMap.checkCharge < 0 ? '↓' : '↑' }}</span>
                <span :title="yesterdayMap.checkCharge">{{ yesterdayMap.checkCharge }}</span>
              </div>
              <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
                <span>较昨日</span>
              </div>
            </div>
            <div class="jin-a" style="width: 24%;padding-left:8px">
              <div class="num" style="font-size: 28px;font-weight: bold;color: #333333;line-height: 41px">
                <span>{{ (todayMap.minutesCount / 60).toFixed(2) }}</span>
              </div>
              <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
                <span>质检时长(小时)</span>
              </div>
            </div>
            <div class="zin-a" style="width: 16%;border-right: 1px solid #e4e4e4">
              <div class="sun" style="font-size: 18px;color: color: #787878;line-height: 41px;height: 41px;">
                <span style="color: #FF0000;font-size: 12px;">{{ yesterdayMap.minutesCount < 0 ? '↓' : '↑' }}</span>
                <span :title="yesterdayMap.minutesCount">{{ (yesterdayMap.minutesCount / 60).toFixed(2) }}</span>
              </div>
              <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
                <span>较昨日</span>
              </div>
            </div>
            <div class="jin-a" style="padding-left:8px;width: 20%">
              <div class="num" style="font-size: 28px;font-weight: bold;color: #333333;line-height: 41px">
                <span>{{ todayMap.uidCount }}</span>
              </div>
              <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
                <span>用户数量</span>
              </div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="card-data" style="padding-right: 5px">
          <div style="padding: 15px 25px;background: #fff;border-radios: 4px;display: flex;
    box-shadow: 1px 1px 5px #999;">
            <div class="jin-a" style="width: 22%">
              <div class="num" style="font-size: 28px;font-weight: bold;color: #333333;line-height: 41px">
                <span>{{ todayMap.checkTotal }}</span>
              </div>
              <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
                <span>今日质检次数</span>
              </div>
            </div>
            <div class="zin-a" style="width: 12%;border-right: 1px solid #e4e4e4">
              <div class="sun" style="font-size: 18px;color: color: #787878;line-height: 41px;height: 41px;">
                <span style="color: #FF0000;font-size: 12px;">{{ yesterdayMap.checkTotal < 0 ? '↓' : '↑' }}</span>
                <span :title="yesterdayMap.checkTotal">{{ yesterdayMap.checkTotal }}</span>
              </div>
              <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
                <span>较昨日</span>
              </div>
            </div>
            <div class="jin-a" style="width: 22%;padding-left:8px;">
              <div class="num" style="font-size: 28px;font-weight: bold;color: #333333;line-height: 41px">
                <span>{{ todayMap.hitTypeCount }}</span>
              </div>
              <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
                <span>今日命中总次数</span>
              </div>
            </div>
            <div class="zin-a" style="width: 12%;border-right: 1px solid #e4e4e4">
              <div class="sun" style="font-size: 18px;color: color: #787878;line-height: 41px;height: 41px;">
                <span style="color: #FF0000;font-size: 12px;">{{ yesterdayMap.hitTypeCount < 0 ? '↓' : '↑' }}</span>
                <span :title="yesterdayMap.hitTypeCount">{{ yesterdayMap.hitTypeCount }}</span>
              </div>
              <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
                <span>较昨日</span>
              </div>
            </div>
            <div class="jin-a" style="width: 22%;padding-left:8px;">
              <div class="num" style="font-size: 28px;font-weight: bold;color: #333333;line-height: 41px">
                <span>{{ todayMap.hitRuleCount }}</span>
              </div>
              <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
                <span>命中高风险数量</span>
              </div>
            </div>
            <div class="zin-a" style="width: 10%">
              <div class="sun" style="font-size: 18px;color: color: #787878;line-height: 41px;height: 41px;">
                <span style="color: #FF0000;font-size: 12px;">{{ yesterdayMap.hitRuleCount < 0 ? '↓' : '↑' }}</span>
                <span :title="yesterdayMap.hitRuleCount">{{ todayMap.hitRuleCount }}</span>
              </div>
              <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
                <span>较昨日</span>
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    <div class="filter-box" style="padding-top: 15px">
      <a-form layout="horizontal" ref="formRef" :model="formState">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="用户名" name="uid">
              <a-select
                  v-model:value="formState.uid"
                  style="width: 100%"
                  placeholder="请选择用户名"
              >
                <a-select-option
                    :value="item.value"
                    :label="item.label"
                    v-for="item in userOptions"
                    :key="item.value"
                >{{ item.label }}
                </a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="服务商" name="amountId">
              <a-select
                  v-model:value="formState.amountId"
                  style="width: 100%"
                  placeholder="请选择服务商"
              >
                <a-select-option
                    :value="item.value"
                    :label="item.label"
                    v-for="item in amountOption"
                    :key="item.value"
                >{{ item.label }}
                </a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="日期" name="start_End">
              <a-config-provider :locale="zhCN">
                <a-range-picker v-model:value="formState.start_End" :locale="locale"/>
              </a-config-provider>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-button type="primary" @click="handelSearch">
                查询
              </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handelReset">
                重置
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-list" style="padding-top: 15px">
      <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="totalTableData"
          size="mini"
          :row-style="{height:'50px'}"
          :cell-style="{padding:'0px'}"
          tooltip-effect="dark"
          style="width: 100%; overflow: auto"
          :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
      >
        <el-table-column label="汇总时间段" prop="nickName"></el-table-column>
        <el-table-column label="服务商" prop="amountCount"></el-table-column>
        <el-table-column label="质检条数" prop="checkTotal"></el-table-column>
        <el-table-column label="命中次数" prop="hitRuleCount"></el-table-column>
        <el-table-column label="转写时长（小时）" prop="minutesCount">
          <template #default="{row}">
            {{ (row.minutesCount / 60).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="消费金额（元）" prop="checkCharge">
          <template #default="{row}">
            {{ (row.checkCharge) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-list" style="padding-top: 15px">
      <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          size="mini"
          :row-style="{height:'50px'}"
          :cell-style="{padding:'0px'}"
          tooltip-effect="dark"
          style="width: 100%; overflow: auto"
          :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
      >
        <el-table-column label="日期" prop="date"></el-table-column>
        <el-table-column label="用户名" prop="nickName"></el-table-column>
        <el-table-column label="服务商" prop="amountName"></el-table-column>
        <el-table-column label="成本单价(元/小时)" prop="userPrice"></el-table-column>
        <el-table-column label="质检条数" prop="checkTotal"></el-table-column>
        <el-table-column label="命中次数" prop="hitTypeCount"></el-table-column>
        <el-table-column label="转写时长（小时）" prop="minutesCount">
          <template #default="{row}">
            {{ (row.minutesCount / 60).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="消费金额（元）" prop="checkCharge">
          <template #default="{row}">
            {{ (row.checkCharge) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pager" style="padding-top: 8px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageInfo.currentPage"
          :page-size="pageInfo.pageSize"
          background :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {reactive} from 'vue';
import _lineService from "@/api/open/LineQualityInspections";
import {PageModule} from '../common/pagination';
import moment from 'moment';
import zhCN from 'ant-design-vue/es/locale/zh_CN';

const date = new Date()
const date1 = new Date(date.getTime() - (24 * 3600 * 1000 * 7))
const year = date.getFullYear()
const n = date.getMonth() + 1
const d = date.getDate()
const str = `${year}-${n > 9 ? n : '0' + n}-${d > 9 ? d : '0' + d}`
const year1 = date1.getFullYear()
const n1 = date1.getMonth() + 1
const d1 = date1.getDate()
const str1 = `${year1}-${n1 > 9 ? n1 : '0' + n1}-${d1 > 9 ? d1 : '0' + d1}`

/*
  "amountCount": 1,  # 服务商数量
  "checkTotal": 1,   # 质检总数量
  "hitRuleCount": 0, # 规则命中数量
  "minutesCount": 2, # 总分种数(分钟)
  "hitTypeCount": 1,  #类型命中数量(原型中命中数量就是这个字段)
  "checkSuccess": 1,  # 成功质检数量
  "checkCharge": 2.0000,  # 质检金额总数
  "uidCount": 1       # 用户数量
*/
class TodayMap {
  constructor(item) {
    this.amountCount = 0
    this.checkTotal = 0
    this.hitRuleCount = 0
    this.minutesCount = 0
    this.hitTypeCount = 0
    this.checkSuccess = 0
    this.checkCharge = 0
    this.uidCount = 0
  }

  setTodayMap(item) {
    this.amountCount = this.keepTwoDecimal(item.amountCount)
    this.checkTotal = this.keepTwoDecimal(item.checkTotal)
    this.hitRuleCount = this.keepTwoDecimal(item.hitRuleCount)
    this.minutesCount = this.keepTwoDecimal(item.minutesCount)
    this.hitTypeCount = this.keepTwoDecimal(item.hitTypeCount)
    this.checkSuccess = this.keepTwoDecimal(item.checkSuccess)
    this.checkCharge = this.keepTwoDecimal(item.checkCharge, 4)
    this.uidCount = this.keepTwoDecimal(item.uidCount)
  }

  keepTwoDecimal(num, digits = 2) {
    var result = parseFloat(num);
    if (isNaN(result)) {
      return 0;
    }
    // result = Math.round(num * 100) / 100;
    // result = Math.round(num * 100) / 100;
    result = num;
    if (result != parseInt(result)) {
      result = result.toFixed(digits);
    }
    return result;
  }
}

class YesterdayMap {
  constructor(item, today) {
    this.amountCount = 0
    this.checkTotal = 0
    this.hitRuleCount = 0
    this.minutesCount = 0
    this.hitTypeCount = 0
    this.checkSuccess = 0
    this.checkCharge = 0
    this.uidCount = 0
  }

  setYesterdayMap(item, today) {
    this.amountCount = this.keepTwoDecimal(today.amountCount - item.amountCount)
    this.checkTotal = this.keepTwoDecimal(today.checkTotal - item.checkTotal)
    this.hitRuleCount = this.keepTwoDecimal(today.hitRuleCount - item.hitRuleCount)
    this.minutesCount = this.keepTwoDecimal(today.minutesCount - item.minutesCount)
    this.hitTypeCount = this.keepTwoDecimal(today.hitTypeCount - item.hitTypeCount)
    this.checkSuccess = this.keepTwoDecimal(today.checkSuccess - item.checkSuccess)
    this.checkCharge = this.keepTwoDecimal(today.checkCharge - item.checkCharge, 4)
    this.uidCount = this.keepTwoDecimal(today.uidCount - item.uidCount)
  }

  keepTwoDecimal(num, digits = 2) {
    var result = parseFloat(num);
    if (isNaN(result)) {
      return 0;
    }
    // result = Math.round(num * 100) / 100;
    result = num;
    if (result != parseInt(result)) {
      result = result.toFixed(digits);
    }
    return result;
  }
}

/*
  createTime 创建时间 updateTime 修改时间 id 主键
  uid 用户id interfaceId 接口id amountId 服务id
  checkTotal 质检总数 checkSuccess 质检成功数 checkCharge 费用统计
  hitTypeCount 类型命中统计(命中统计展示) hitRuleCount 规则命中统计
  minutesCount 分钟统计(分钟,需要除以60分钟换为小时展示) date 日期
  recognitionTotal ？ recognitionSuccess ？ recognitionMinutes ？
  nickName 用户名称 amountName 服务商名称 userPrice 用户成本价格
*/
class TableModule {
  constructor(item) {
    this.createTime = item.createTime
    this.updateTime = item.updateTime
    this.id = item.id
    this.uid = item.uid
    this.interfaceId = item.interfaceId
    this.amountId = item.amountId
    this.checkTotal = item.checkTotal
    this.checkSuccess = item.checkSuccess
    this.checkCharge = item.checkCharge
    this.hitTypeCount = item.hitTypeCount
    this.hitRuleCount = item.hitRuleCount
    this.minutesCount = item.minutesCount
    this.date = item.date
    this.recognitionTotal = item.recognitionTotal
    this.recognitionSuccess = item.recognitionSuccess
    this.recognitionMinutes = item.recognitionMinutes
    this.nickName = item.nickName
    this.amountName = item.amountName
    this.userPrice = item.userPrice
  }
}

/*
  "dateEnd":"2022-07-29",  # 结束时间(必传)
  "dateBegin":"2022-07-01", # 开始时间(必传)
  "uid":100008,             # 用户id
  "amountId":"9",           # 服务商id
  "sortBy":["check_total"],  # 排序字段
  "sortDesc":[false]         # 排序规则
*/
class FormModule {
  constructor() {
    this.start_End = [moment(str, 'YYYY-MM-DD'), moment(str, 'YYYY-MM-DD')]
    this.uid = undefined
    this.amountId = undefined
  }

  getParamsJson() {
    return {
      dateEnd: this.start_End[1].format('YYYY-MM-DD'),
      dateBegin: this.start_End[0].format('YYYY-MM-DD'),
      uid: this.uid,
      amountId: this.amountId
    }
  }
}

export default {
  props: {
    amountOption: {
      type: Array,
      default: () => []
    },
    userOptions: {
      type: Array,
      default: () => []
    },
    isAdminUser: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      tableData: [],
      totalTableData: [],
      loading: false,
      formState: reactive(new FormModule()),
      todayMap: new TodayMap(),
      yesterdayMap: new YesterdayMap(),
      pageInfo: new PageModule(),
      zhCN: zhCN
    };
  },
  methods: {
    async getTotalCount() {
      const res = await _lineService._recordService.getTotalCount()
      this.todayMap.setTodayMap(res.todayMap)
      this.yesterdayMap.setYesterdayMap(res.yesterdayMap, res.todayMap)
    },
    async getCheckCount() {
      const res = await _lineService._recordService.getCheckCount(this.getparams())
      const obj = new TodayMap()
      obj.setTodayMap(res.count)
      this.totalTableData = [obj];
      this.tableData = res.page.records.map(item => new TableModule(item))
      this.pageInfo.setTotal(res.page.total)
    },
    getparams() {
      return {
        ...this.pageInfo.getParamsJson(),
        ...this.formState.getParamsJson()
      }
    },
    handelSearch() {
      this.pageInfo.init()
      this.getCheckCount()
    },
    handleSizeChange(val) {
      this.pageInfo.setPageSize(val)
      this.getCheckCount()
    },
    handleCurrentChange(val) {
      this.pageInfo.setCurrentPage(val)
      this.getCheckCount()
    },
    handelReset() {
      this.$refs.formRef.resetFields()
      this.handelSearch()
    }
  },
  created() {
    this.getTotalCount()
    this.getCheckCount()
  }
};
</script>
<style scoped>
.num {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sun {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>